import { Input, Component, OnInit } from '@angular/core';
import { NgMapService } from '../ng-map.service';
import Map from 'ol/Map';

@Component({
  selector: 'ng-map-control-zoom',
  templateUrl: './control-zoom.component.html',
  styleUrls: ['./control-zoom.component.scss']
})
export class ControlZoomComponent implements OnInit {

  @Input() maps: { [key: string]: Map } = {};
  @Input() animate?: boolean;
  @Input() duration?: number;

  constructor(protected mapService: NgMapService) { }

  ngOnInit() {
    // empty
  }

  onZoomIn() {
    this.mapService.zoomIn(Object.values(this.maps)[0], this.animate, this.duration);
  }

  onZoomOut() {
    this.mapService.zoomOut(Object.values(this.maps)[0], this.animate, this.duration);
  }
}
