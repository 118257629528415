import { Component, OnInit } from '@angular/core';
import { DomSanitizer, Meta, SafeHtml, Title } from '@angular/platform-browser';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { MetaService, SnackbarService } from '@sciamlab/ng-common';
import { CollectionTypeComponent, StrapiService, StrapiV4MetaService } from '@sciamlab/ng-common-strapi';
import { environment } from 'src/environments/environment';

@Component({
   selector: 'app-page-view',
   templateUrl: './page-view.component.html',
   styleUrls: ['./page-view.component.scss'],
})
export class PageViewComponent extends CollectionTypeComponent implements OnInit {
   environment = environment;
   page_content_rendered!: SafeHtml;
   constructor(
      route: ActivatedRoute,
      router: Router,
      strapi: StrapiService,
      meta: MetaService,
      private sanitizer: DomSanitizer,
      snackbarService: SnackbarService,
      translate: TranslateService,
   ) {
      super(route, router, snackbarService, translate, meta, strapi);
   }

   async ngOnInit(): Promise<void> {
      await super.ngOnInit();
      
      this.page_content_rendered = this.sanitizer.bypassSecurityTrustHtml(this.model.content)

      this.webpage = {
         ...this.webpage,
         name: this.model?.seo?.metaTitle || this.model?.title || this.settings?.seo?.metaTitle || this.settings?.title,
         description: this.model?.seo?.metaDescription || this.model?.description || this.settings?.seo?.metaDescription || this.settings?.description,
      }; 
   }

   getCoverUrl(): string {
      return this.strapi.getImageUrl(this.model.cover);
   }
}
