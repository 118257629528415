import { isPlatformBrowser, Location } from '@angular/common';
import { HttpClient, HttpParams } from '@angular/common/http';
import { AfterViewInit, Component, Inject, OnDestroy, OnInit, PLATFORM_ID } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Map, MapEvent, View } from 'ol';
import { AppComponent } from 'src/app/app.component';
import { CollectionTypeComponent, StrapiService } from '@sciamlab/ng-common-strapi';
import { environment } from 'src/environments/environment';
import { MetaService, SnackbarService } from '@sciamlab/ng-common';
import { NgMapService } from '../ng-map/ng-map.service';
import { TileWMS } from 'ol/source';
import { InfoboxService } from './infobox/infobox.service';
import TileLayer from 'ol/layer/Tile';
import { Options } from '@angular-slider/ngx-slider';

@Component({
   selector: 'app-map',
   templateUrl: './map.component.html',
   styleUrls: ['./map.component.scss'],
})
export class MapComponent extends CollectionTypeComponent implements OnInit, OnDestroy {
   environment = environment;
   settings: any;
   center!: [number, number];
   zoom!: number;
   minZoom!: number;
   maxZoom!: number;
   target = 'map';
   selected_layer: { [key: string]: any } = {};
   selected_theme: { [key: string]: any } = {};
   selected_theme_layers: { [key: string]: {name: string, group: boolean}[] } = {};
   selected_filter: { [key: string]: { [key: string]: any } } = {};
   base_layers: any[] = [];
   country_layer: any;
   griglia_layer: any;
   layers: { [key: string]: any } = {};
   maps: { [key: string]: Map } = {};
   view!: View;
   render = false;
   Array = Array;
   Object = Object;
   isPlatformBrowser: boolean;
   current_view!: number;
   coords: any;
   opacity: number;
   opacity_toggler_clicked = false;

   selected_features: { [key: string]: any[] } = {};

   // map_themes!: { [key:string]: any };
   all_themes: any[] = [];
   filters: { [key: string]: any[] } = {};
   clicked = false;
   loading_layers = false;

   tiles_loading: { [key: string]: number } = {}
   tiles_loaded: { [key: string]: number } = {}
   tiles_progress = [0,0];

   queryParams!: HttpParams;

   opacity_options: Options = {
      floor: 0,
      ceil: 1,
      step: 0.01,
      showSelectionBar: true,
      animate: false
   };

   zones: any = [];
   
   constructor(
      route: ActivatedRoute,
      router: Router,
      strapi: StrapiService,
      translate: TranslateService,
      meta: MetaService,
      snackbarService: SnackbarService,
      protected infoboxService: InfoboxService,
      protected mapService: NgMapService,
      protected location: Location,
      private appComponent: AppComponent,
      private http: HttpClient,
      @Inject(PLATFORM_ID) private platformId: any,
   ) {
      super(route, router, snackbarService, translate, meta, strapi);
      appComponent.cssClass = 'homepage';
      this.isPlatformBrowser = isPlatformBrowser(this.platformId);
      this.base_layers = this.settings.base_layers;
      this.country_layer = this.settings.country_layer;
      this.griglia_layer = this.settings.griglia_layer;
      this.opacity = this.settings.layer_default_opacity || 1;
      this.queryParams = Object.entries(this.route.snapshot.queryParams).reduce((acc,e)=>acc.set(e[0],e[1]), new HttpParams())
   }

   async ngOnInit(): Promise<void> {
      await super.ngOnInit();

      this.filters = await this.strapi.getSingleType('filter');
      console.log('filters', this.filters);

      this.center = JSON.parse(this.settings.map_center);
      this.zoom = this.settings.map_zoom;
      this.minZoom = this.settings.map_minZoom;
      this.maxZoom = this.settings.map_maxZoom;
      this.view = this.mapService.createView(this.center, this.zoom, this.minZoom, this.maxZoom);

      this.zones = (await this.strapi.filterCollectionType(
         'zones',
         undefined,
      )).data

      const _themes = (await this.strapi.filterCollectionType(
         'maps',
         undefined,
         undefined,
         undefined,
         undefined,
         this.translate.currentLang,
         'theme.filters',
      )).data

      this.all_themes = _themes.reduce((acc: any[], m: { slug: string; title: string; theme: any[] }) => {
         return [
            ...acc,
            ...m.theme.map((t: { filters: any[] }) => {
               return {
                  ...t,
                  parent: { slug: m.slug, title: m.title },
               };
            }),
         ];
      }, []);
      console.log('all_themes', this.all_themes);

      await this.setVista(this.queryParams.has('view') ? +(this.queryParams.get('view') as string) : 1);
   }

   async onResize(event: any): Promise<void> {
      if (event.target.innerWidth <= 768) {
         await this.setVista(1);
      }
   }

   toggle(): void {
      this.clicked = !this.clicked;
   }

   async onMap(map: Map, view: number): Promise<void> {
      const target = map.getTarget() as string;
      this.maps[target] = map;
      console.log('created', target, map);

      const self = this;
      map.on('singleclick', async (evt) => {
         // document.getElementById('info').innerHTML = '';

         const viewResolution = this.view.getResolution();

         for (let index = 1; index <= this.current_view; index++) {
            const all_layers = self.maps[this.target + index.toString()].getAllLayers();
            if (all_layers.length < 3) continue;
            const source: TileWMS = all_layers[1].getSource() as TileWMS;

            const url = source.getFeatureInfoUrl(evt.coordinate, viewResolution as number, 'EPSG:3857', {
               INFO_FORMAT: 'application/json',
            });
            if (!url) this.snackbarService.error('getFeatureInfoUrl is null');
            try {
               const response = await this.http.get<{ features: any[] }>(url as string).toPromise();
               this.selected_features[index.toString()] = (response as { features: any[] }).features;
            } catch (err: any) {
               this.snackbarService.error(err.toString());
            }
         }
      });

      // solo se sono sulla prima mappa
      if (target === this.target + '1') {
         //centro la mappa se c'è @
         if (this.queryParams.has('@')) {
            const center: number[] = [
               +(this.queryParams.get('@') as string).split(',')[1],
               +(this.queryParams.get('@') as string).split(',')[0],
            ];
            const zoom: number = +(this.queryParams.get('@') as string).split(',')[2];
            this.mapService.goToCenter(map, center, zoom, false);
         }

         map.on('moveend', ($event: MapEvent) => {
            console.log($event.map.getTarget());
            this.coords = this.mapService.getCoordinates($event.map);
            console.log('center', this.coords.center, 'zoom', this.coords.zoom);
            this.queryParams = this.queryParams
               .set('@', `${this.coords.center.join()},${this.coords.zoom}`)
               .set('view', this.current_view);
            this.location.replaceState(this.location.path().split('?')[0], this.queryParams.toString());
         });
      }

      // recupero i valori dalla url
      const _url_layer: string | null = this.queryParams.get(`layer${view}`);
      if (_url_layer || view==1) {
         const _url_map: string = _url_layer?.split(':')[0].split('_')[0] || this.model.slug;
         const _url_theme: string = _url_layer?.split(':')[0].split('_')[1] || this.model.theme[0].name;
         const _url_filter: string | undefined = _url_layer?.split(':')[1];

         // seleziono il tema
         const theme =  this.all_themes.find((t:any)=>t.parent.slug==_url_map && t.name==_url_theme);
         this.selected_theme[view.toString()] = theme ;

         // recupero i layer disponibili per il tema
         const theme_layers = await this._getThemeLayers(_url_map, theme);
         this.selected_theme_layers[view.toString()] = theme_layers;
         
         // inizializzo il filtro con i parametri della url o con il primo layer disponibile per il tema
         const filter = _url_filter 
            ? this._selectFilterBasedOnURL(_url_filter, theme.filters) 
            : this._selectFirstFilterOnTheme(theme_layers, theme.filters);
         this.selected_filter[view.toString()] = filter;
         
         // creo il nuovo layer
         const layer = this._getNewLayer(view, _url_map, theme, filter);
         this.selected_layer[view.toString()] = layer,
      
         // notifico alla veletta che è stato aggiornato il filtro
         this.infoboxService.updateFiltersOptions(view, this.filters, theme, theme_layers, filter, layer);

         // aggiungo il layer alla mappa
         if (layer) this._setLayer(view, layer);
            
         // aggiorno la url
         if (layer?.options?.source?.params?.LAYERS) 
            this.queryParams = this.queryParams.set(`layer${view}`, layer?.options?.source?.params?.LAYERS)
      }
      this.location.replaceState(this.location.path().split('?')[0], this.queryParams.toString());
   }

   async setVista(view = 1): Promise<void> {
      console.log('setVista', view);
      this.selected_features = {};

      for (let index = 1; index <= view; index++) {
         // aggiungo i layers alla mappa
         this.layers[index.toString()] = [this.base_layers[0]];
         this.layers[index.toString()].push(this.country_layer);
         this.layers[index.toString()].push(this.griglia_layer);
      }
      this.current_view = view;
      this.queryParams = this.queryParams.set(`view`, view)
      this.render = false;
      setTimeout(async () => {
         this.render = true;
      }, 100);
      // prosegue nel metodo onMap
   }

   async onChangeMapTheme($event: [number, any]): Promise<void> {
      const view = $event[0];
      const theme = $event[1];
      console.log('onChangeMapTheme', view, theme);
      this.selected_theme[view.toString()] = theme;
      this.selected_theme_layers[view.toString()] = await this._getThemeLayers(
         view == 1 ? this.model.slug : this.selected_theme[view.toString()].parent.slug, 
         this.selected_theme[view.toString()],
      );

      // inizializzo il filtro con il primo layer disponibile per il tema
      this.selected_filter[view.toString()] = this._selectFirstFilterOnTheme(
         this.selected_theme_layers[view.toString()], 
         this.all_themes.find((t: any) => t.name == theme.name)?.filters
      );

      this.selected_features[view.toString()] = [];
      const layer = this._getNewLayer(
         view,
         view == 1 ? this.model.slug : this.selected_theme[view.toString()].parent.slug,
         this.selected_theme[view.toString()],
         this.selected_filter[view.toString()],
      );
      this.selected_layer[view.toString()] = layer;
      this.layers[view.toString()].splice(1, 0, layer);
      // this.mapService.setLayer(this.maps[this.target + view.toString()], 2, layer);
      if(layer) this._setLayer(view, layer);

      this.infoboxService.updateFiltersOptions(
         view, 
         this.filters,
         this.selected_theme[view.toString()], 
         this.selected_theme_layers[view.toString()], 
         this.selected_filter[view.toString()], 
         this.selected_layer[view.toString()]
      );

      this.queryParams = this.queryParams.set(`layer${view}`, layer?.options?.source?.params?.LAYERS)
      this.location.replaceState(this.location.path().split('?')[0], this.queryParams.toString());
   }

   private _selectFilterBasedOnURL(url_filter: string, filters: { name: string }[]): { [key: string]: { name: string }} {
      const filter = filters.reduce((acc: { [key: string]: { name: string }}, f: { name: string }, i: number) => {
         return { ...acc, [f.name]: 
            { name: url_filter.split('_')[i] }
         }
      }, {});
      return filter;
   }
   private _selectFirstFilterOnTheme(selected_theme_layers: {name: string, group: boolean}[], filters: { name: string }[]): { [key: string]: { name: string }} {
      const filter = filters.reduce((acc: { [key: string]: { name: string }}, f: { name: string }, i: number) => {
         if (!selected_theme_layers[0]) return acc;
         return { ...acc, [f.name]: 
            { name: selected_theme_layers[0]?.name.split("_")[i] }
         }
      }, {});
      return filter;
   }

   onChangeOpacity(opacity: number): void {
      this.opacity = opacity;
      Array(this.current_view).fill(1).forEach((view,i)=>{
         ++i
         const _map = this.maps[this.target + i.toString()];
         if(_map.getLayers().getArray().length==4) // solo se c'era il livello da sostituire, altrimenti salto
            _map.getLayers().getArray()[1].setOpacity(opacity);
      });
   }

   async onChangeFilter($event: [number, { name: string }, { name: string }, boolean]): Promise<void> {
      const view = $event[0];
      const filter = $event[1];
      const value = $event[2];
      const layer_availability = $event[3];
      console.log('onChangeFilter', view, filter.name, value.name, layer_availability);
      if (!this.selected_filter[view.toString()]) this.selected_filter[view.toString()] = {};
      this.selected_filter[view.toString()][filter.name] = value;
      if (!layer_availability){
         /**
          * è stato selezionato un valore del filtro che non ha una combinazione disponibile
          * devo allineare gli altri filtri
          */
         // questo è l'indice del filtro corrispondente al valore selezionato
         const filter_index = Object.keys(this.selected_filter[view.toString()]).indexOf(filter.name);
         // questi sono i layer che contengono quel valore
         const filtered_layers = this.selected_theme_layers[view.toString()].filter(l=>l.name.split('_')[filter_index]==value.name)
         // ora seleziono una combinazione di filtri valida
         Object.entries(this.selected_filter[view.toString()]).forEach(([_filter, _value], _index) => {
            this.selected_filter[view.toString()][_filter] = { name: filtered_layers[0].name.split('_')[_index]}
         });
      }

      this.selected_features[view.toString()] = [];
      const layer = this._getNewLayer(
         view,
         view == 1 ? this.model.slug : this.selected_theme[view.toString()].parent.slug,
         this.selected_theme[view.toString()],
         this.selected_filter[view.toString()],
      );
      this.selected_layer[view.toString()] = layer;
      this.layers[view.toString()].splice(1, 0, layer);
      // this.mapService.setLayer(this.maps[this.target + view.toString()], 2, layer);
      if(layer) this._setLayer(view, layer);

      this.infoboxService.updateFiltersOptions(
         view, 
         this.filters,
         this.selected_theme[view.toString()], 
         this.selected_theme_layers[view.toString()], 
         this.selected_filter[view.toString()], 
         this.selected_layer[view.toString()]
      );

      this.queryParams = this.queryParams.set(`layer${view}`, layer?.options?.source?.params?.LAYERS)
      this.location.replaceState(this.location.path().split('?')[0], this.queryParams.toString());

      if (filter.name == 'zone') {
         /**
          * centro la mappa se è variato il filtro zone
          */
         switch (value.name) {
            case 'ww':
               this.mapService.goToCenter(this.maps[this.target + '1'], this.center, this.zoom)
               break;
            default: {
               // if (this.settings.nominatim_server) {
               //    try {
               //       const country: any[] = (await this.http.get<any[]>(this.settings.nominatim_server, { params: {
               //          format: 'json',
               //          country: value.name
               //       }}).toPromise()) as any[];
               //       console.log('country', country);
               //       if (country.length) {
               //          this.mapService.goToExtent(this.maps[this.target + '1'], [
               //             [country[0].boundingbox[2], country[0].boundingbox[0]],
               //             [country[0].boundingbox[3], country[0].boundingbox[1]]
               //           ])
               //       }
                     
               //    } catch (err: any) {
               //       this.snackbarService.error(err.toString());
               //    }
               // }
               const z = this.zones.find((z:any) => z.code==value.name)
               if (z) {
                  const bbox = JSON.parse(z.bbox);
                  this.mapService.goToExtent(this.maps[this.target + '1'], [
                     [bbox[0], bbox[1]],
                     [bbox[2], bbox[3]]
                  ])
               }
               break;
            }
         }
      }
   }

   async _getThemeLayers(map: string, theme: any): Promise<{name: string, group: boolean}[]> {
      try {
         this.loading_layers = true;

         let layers = (await this.http.get<any>(environment.geoserver+`/rest/workspaces/${map}_${theme.name}/layers`).toPromise()).layers;
         layers = layers.layer 
            ? layers.layer//.map((l:any)=>l.name) 
            : [];

         return layers;
         
         // let layergroups = (await this.http.get<any>(environment.geoserver+`/rest/workspaces/${map}_${theme.name}/layergroups`).toPromise()).layerGroups;
         // layergroups = layergroups.layerGroup ? layergroups.layerGroup.map((l:any)=>({ ...l, group: true })) : [];
         
         // return [ ...layers, ...layergroups];

      } catch(err:any) {
         this.snackbarService.error(err.toString())
         return [];

      } finally {
         this.loading_layers = false;
      }
   }
   _getNewLayer(view: number, map: string, theme: any, filter: any): any {
      if (!Object.keys(filter).length) return null;
      const LAYERS = `${map}_${theme.name}:${Object.values(filter).map((f: any) => f.name).join('_')}`;
      return {
         ... this.selected_theme_layers[view.toString()].find(l=>l.name==LAYERS.split(':')[1]),
         type: 'wms',
         options: {
            visible: true,
            opacity: this.opacity,
            source: {
               url: environment.geoserver+`/${map}_${theme.name}/wms`,
               params: {
                  TILED: true,
                  LAYERS,
               },
            },
         },
      };
   }

   _setLayer(view: number, layer:any): void{
      const _map = this.maps[this.target + view.toString()];
      const _source = new TileWMS(layer.options.source)
      this.tiles_loading[view.toString()]=0
      this.tiles_loaded[view.toString()]=0
      this.tiles_progress=[0,0]
      _source.on('tileloadstart', () => {
         ++this.tiles_loading[view.toString()]
         ++this.tiles_progress[1]
      });
      _source.on(['tileloadend', 'tileloaderror'], () => {
         ++this.tiles_loaded[view.toString()]
         ++this.tiles_progress[0]
      });
      if (layer.options.minZoom == null) delete layer.options.minZoom
      if (layer.options.maxZoom == null) delete layer.options.maxZoom
      const _layer = new TileLayer({...layer.options, source: _source})
      _map.getLayers().insertAt(1, _layer);
      if(_map.getLayers().getArray().length==5) // solo se c'era il livello da sostituire, altrimenti salto
         _map.removeLayer(_map.getLayers().getArray()[2]);
   }

   ngOnDestroy(): void {
      this.appComponent.cssClass = '';
   }

   onOpacityChange(changeContext: any): void {
      this.onChangeOpacity(this.opacity);
   }
}
