<div class="dl-footer text-dark-80 bg-light border-top" *ngIf="model && settings">
    <div class="container">
        <div class="row">
            <div class="col-md-9 col-xs-12 about-company">
                <div style="background-image: url('{{ strapi.getImageUrl(settings.meta.image) }}');" class="logo"></div>

                <div class="py-5" style="width: 50%;">
                    <p class="text-secondary funder_info">{{settings.funder_info}}</p>
                </div>

                <div class="d-sm-flex">
                    <div *ngFor="let img of this.settings.funder_image" style="background-image: url('{{ strapi.getImageUrl(img.image) }}');" class="logo-footer py-2 me-5 mb-5"></div>
                </div>

                <div style="padding: 0 0 2rem 0;">
                    <div class="row" *ngIf="model.links">
                        <div *ngFor="let item of model.links" class="col-md-4">
                            <h5 class="mt-2">
                                <a class="fluo inverse text-dark" *ngIf="item.routing.type == 'SINGLE_TYPE'" routerLink="/{{ item.routing.location }}">{{ item.routing.title }}</a>
                            </h5>
            
                            <h5 class="mt-2">
                                <a class="fluo inverse text-dark" *ngIf="item.routing.type == 'PAGE'" [routerLink]="['/page', item.routing.location]">{{ item.routing.title }}</a>
                            </h5>
            
                            <h5 class="mt-2">
                                <a class="fluo inverse text-dark" *ngIf="item.routing.type == 'NEWS'" [routerLink]="['/news', item.routing.location, item.id]">{{ item.routing.title }}</a>
                            </h5>

                            <h5 class="mt-2">
                                <a class="fluo inverse text-dark" *ngIf="item.routing.type == 'URL'" target="_blank" routerLink="{{ item.routing.location }}">{{ item.routing.title }}</a>
                            </h5>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-3 col-xs-12 location text-dark">
                <h4 class="mt-lg-0 mt-sm-4">{{ model.title }}</h4>
                <p>{{ model.info.address }}<br>{{ model.info.country }}</p>
                <p *ngIf="model.info.phone" class="mb-0"><i class="bi bi-telephone-fill me-2"></i> {{ model.info.phone }}</p>
                <p *ngIf="model.info.mail"><i class="bi bi-envelope-fill me-2"></i>{{ model.info.mail }}</p>
            </div>
      </div>
   </div>
</div>   
