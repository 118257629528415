import {Component, OnInit, Input, OnChanges, SimpleChanges, Host, ElementRef, Optional} from '@angular/core';
import Attribution from 'ol/control/Attribution';
import { NgMapService } from '../ng-map.service';
import Map from 'ol/Map';
import { NgMapComponent } from '../ng-map.component';


@Component({
  selector: 'ng-map-control-attribution',
  template: ''
})
export class ControlAttributionComponent implements OnInit, OnChanges {

  @Input() map!: Map;
  @Input() options: any = {};
  attribution!: Attribution;

  constructor(
    private mapService: NgMapService,
    private elementRef: ElementRef,
    @Optional() @Host() private _mapDom: NgMapComponent
  ) {}

  ngOnInit() {
      if (!this._mapDom) {
        this.options.target = this.elementRef.nativeElement;
      }
      this.attribution = new Attribution(this.options);
      this.map.addControl(this.attribution);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.attribution) {
      this.attribution.setProperties(this.options);
    }
  }
}
