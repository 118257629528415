<button 
   class="{{ active ? 'active' : '' }}" 
   (click)="toggleBaselayer()"
   type="button" 
   placement="left" 
   ngbTooltip="{{'MAP.BASE_LAYER' | translate}}"
>
   <svg
      aria-hidden="true"
      focusable="false"
      data-prefix="far"
      data-icon="map"
      class="svg-inline--fa fa-map fa-w-18"
      role="img"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 576 512"
      width="20"
      height="20"
   >
      <path
         fill="currentColor"
         d="M560.02 32c-1.96 0-3.98.37-5.96 1.16L384.01 96H384L212 35.28A64.252 64.252 0 0 0 191.76 32c-6.69 0-13.37 1.05-19.81 3.14L20.12 87.95A32.006 32.006 0 0 0 0 117.66v346.32C0 473.17 7.53 480 15.99 480c1.96 0 3.97-.37 5.96-1.16L192 416l172 60.71a63.98 63.98 0 0 0 40.05.15l151.83-52.81A31.996 31.996 0 0 0 576 394.34V48.02c0-9.19-7.53-16.02-15.98-16.02zM224 90.42l128 45.19v285.97l-128-45.19V90.42zM48 418.05V129.07l128-44.53v286.2l-.64.23L48 418.05zm480-35.13l-128 44.53V141.26l.64-.24L528 93.95v288.97z"
      ></path>
   </svg>
</button>
