import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Subject } from "rxjs";
import { environment } from "src/environments/environment";

@Injectable({
    providedIn: 'root'
})
export class InfoboxService {

    private updateFilterAvailabilitySource = new Subject<any>();
    onUpdateFilter = this.updateFilterAvailabilitySource.asObservable();

    constructor(
        private http: HttpClient,
    ) { }

    async updateFiltersOptions(view: number, filters: { [key: string]: { name: string }[] }, selected_theme: any, selected_theme_layers: {name: string, group: boolean}[], selected_filter: {[key: string]: any}, selected_layer: any): Promise<void> {
        const isFilterOptionValid: {[key: string]: {[key: string]: any}} = {}
        const isLayerAvailable: {[key: string]: {[key: string]: any}} = {}

        if (selected_theme) {
            selected_theme.filters.forEach((f: { name: string }) => {
                isFilterOptionValid[f.name] = {}
                isLayerAvailable[f.name] = {}

                filters[f.name].forEach((fv: { name: string }) => {
                    isFilterOptionValid[f.name][fv.name] = this._checkFilterOptionValidity(selected_theme_layers, selected_filter, f, fv)
                    isLayerAvailable[f.name][fv.name] = this._checkLayerAvailability(selected_theme_layers, selected_filter, f, fv)
                })
            })
        }

        let legend;
        if(selected_layer.group) {
            const layergroup = (await this.http.get<any>(selected_layer.href).toPromise());
            if (layergroup?.layerGroup?.publishables?.published[0]?.name)
                legend = layergroup?.layerGroup?.publishables?.published[0]?.name
        }
        legend = environment.geoserver+`/wms?REQUEST=GetLegendGraphic&FORMAT=image/png&LAYER=${encodeURIComponent(legend || selected_layer.options.source.params.LAYERS)}`;
        this.updateFilterAvailabilitySource.next({ view, selected_filter, isFilterOptionValid, isLayerAvailable, legend});
    }

    /**
     * verifica se la combinazione ottenuta con il valore del filtro selezionato è inclusa nei layer del tema 
     * se non inclusa il valore sarà grigio ma selezionabile ugualmente
     * se selezionato la combinazione degli altri filtri sarà modificata di conseguenza
     */
    private _checkLayerAvailability(selected_theme_layers: {name: string, group: boolean}[], selected_filter: {[key: string]: any}, filter: { name: string }, value: { name: string }) {
        if (!selected_filter) return false;
        const candidate_layer = Object.values({...selected_filter, [filter.name]: value}).map((f: any) => f.name).join('_');
        return selected_theme_layers?.map(l=>l.name).includes(candidate_layer);
    }

    /**
     * verifica se il valore del filtro selezionato è incluso in assoluto nei layer del tema 
     * se non incluso allora il valore sarà barrato e non selezionabile
     */
    private _checkFilterOptionValidity(selected_theme_layers: {name: string, group: boolean}[], selected_filter: {[key: string]: any}, filter: { name: string }, value: { name: string }) {
        if (!selected_filter) return false;
        const filter_index = Object.keys(selected_filter).indexOf(filter.name);
        const filter_available_values = selected_theme_layers?.map(f=>f.name.split('_')[filter_index])
        return filter_available_values?.includes(value.name)
    }
}