import { Component, Injectable, OnInit } from '@angular/core';
import { ActivatedRoute, Resolve, Router } from '@angular/router';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { StrapiService, StrapiV4MetaService, CollectionTypeListComponent } from '@sciamlab/ng-common-strapi';
import { environment } from 'src/environments/environment';
import * as luxon from 'luxon';
import { MetaService, SnackbarService } from '@sciamlab/ng-common';

@Component({
   selector: 'app-news-list',
   templateUrl: './news-list.component.html',
   styleUrls: ['./news-list.component.scss'],
})
export class NewsListComponent extends CollectionTypeListComponent implements OnInit {
   model: any;
   langChangeSubscription!: Subscription;
   page = 1;
   pageSize = 3;
   collectionSize = this.model.length;
   news: any[] = [];
   environment = environment;
   luxon = luxon;

   constructor(
      route: ActivatedRoute,
      router: Router,
      strapi: StrapiService,
      translate: TranslateService,
      snackbarService: SnackbarService,
      meta: MetaService,
   ) {
      super(route, router, snackbarService, translate, meta, strapi);
      this.refreshNews();
   }

   async ngOnInit(): Promise<void> {
      await super.ngOnInit();
   }

   async refreshNews() {
      const offset = (this.page - 1) * this.pageSize;

      this.news = await this.strapi.filterNews('', offset, this.pageSize, 'id:ASC', this.translate.currentLang);
   }
}
