import { Injectable, NgModule } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterModule, Routes } from '@angular/router';
import { NewsViewComponent } from './components/news-view/news-view.component';
import { NewsListComponent } from './components/news-list/news-list.component';
import { PageViewComponent } from './components/page-view/page-view.component';
import { ErrorComponent } from './components/error/error.component';
import { CollectionTypeListResolver, CollectionTypeResolver, SingleTypeResolver } from '@sciamlab/ng-common-strapi';
import { MapComponent } from './components/map/map.component';
import { DocumentationComponent } from './components/documentation/documentation.component';
import { HomeComponent } from './components/home/home.component';

const routes: Routes = [
   {
      path: '',
      component: HomeComponent,
      runGuardsAndResolvers: 'always',
      data: {
         id: 'home',
         populate: 'carousel.image,carousel.routing,seo.metaImage,card.image,localizations,layers.options,base_layers.options,links.slug',
      },
      resolve: {
         model: SingleTypeResolver,
      },
   },
   {
      path: 'documentation',
      component: DocumentationComponent,
      runGuardsAndResolvers: 'always',
      data: {
         id: 'documentation',
         populate: 'localizations,seo,article,brochure,tutorial,video',
      },
      resolve: {
         model: SingleTypeResolver,
      },
   },
   {
      path: 'page',
      children: [
         {
            path: ':id',
            component: PageViewComponent,
            runGuardsAndResolvers: 'always',
            data: {
               id: 'pages',
               populate: 'seo.metaImage,cover'
            },
            resolve: {
               model: CollectionTypeResolver,
            },
         },
      ],
   },
   {
      path: 'map',
      children: [
         {
            path: ':id',
            component: MapComponent,
            runGuardsAndResolvers: 'always',
            data: {
               id: 'maps',
               populate: 'theme.filters,seo.metaImage',
            },
            resolve: {
               model: CollectionTypeResolver,
            },
         },
      ],
   },
   {
      path: 'news',
      children: [
         {
            path: '',
            component: NewsListComponent,
            runGuardsAndResolvers: 'always',
            data: {
               id: 'news',
            },
            resolve: {
               model: CollectionTypeListResolver,
            },
         },
         {
            path: ':id',
            component: NewsViewComponent,
            runGuardsAndResolvers: 'always',
            data: {
               id: 'news',
            },
            resolve: {
               model: CollectionTypeResolver,
            },
         },
      ],
   },
   {
      path: 'error/:code',
      component: ErrorComponent,
   },
   {
      path: '**',
      component: ErrorComponent,
   },
];

@NgModule({
   imports: [
      RouterModule.forRoot(routes, {
         // https://angular.io/api/router/InitialNavigation
         // https://stackoverflow.com/questions/63480936/angular-universal-requires-initialnavigation-enabled-which-breaks-app-initialize
         // initialNavigation: 'enabledBlocking',
         onSameUrlNavigation: 'reload',
      }),
   ],
   exports: [RouterModule],
   providers: [SingleTypeResolver, CollectionTypeResolver],
})
export class AppRoutingModule {}
