<ngx-json-ld *ngIf="meta_enabled" [json]="webpage"></ngx-json-ld>

<div class="component home" *ngIf="model">
   <div class="mb-4">
      <swiper [config]="config">
         <ng-template *ngFor="let c of model.carousel" #swiper swiperSlide class="carousel_card">
            <div class="cover-container">
               <div style="background-image: url('{{ getImageUrl(c.image) }}');" class="home-cover-image"></div>
               <div class="content">
                  <div class="container-fluid pb-3 background-section">
                     <h1>{{ c.title }}</h1>
                     <p>{{ c.description }}</p>
                     <a class="carousel-link" *ngIf="c.routing.type == 'SINGLE_TYPE'" routerLink="/{{ c.routing.location }}">{{ c.routing.title }}</a>

                     <a class="carousel-link" *ngIf="c.routing.type == 'PAGE'" [routerLink]="['/page', c.routing.location]">{{ c.routing.title }}</a>
      
                     <a class="carousel-link" *ngIf="c.routing.type == 'NEWS'" [routerLink]="['/news', c.routing.location, c.id]">{{ c.routing.title }}</a>
      
                     <a class="carousel-link" *ngIf="c.routing.type == 'URL'" target="_blank" routerLink="{{ c.routing.location }}">{{ c.routing.title }}</a>
                  </div>
               </div>
            </div>
         </ng-template>
      </swiper>
   </div>

   <div class="container-fluid mt-5">
      <div class="map-cards row mb-4" *ngIf="maps">
         <div *ngFor="let m of maps" class="col-12 col-sm-6 {{ maps.length === 1 ? 'col-md-12' : (maps.length === 2 ? 'col-md-6' : (maps.length === 3 ? 'col-md-4' : 'col-md-3')) }} mb-5 d-flex justify-content-center">
            <div class="card" style="width: 100%">
               <div style="height: 206px;">
                  <img style="position: relative;" ngSrc="{{ strapi.getImageUrl(m.seo.metaImage, 'medium') }}" class="carousel-image" fill>
               </div>
               <div class="card-body">
                  <h5 class="card-title">{{ m.title }}</h5>
                  <p class="card-text">{{ m.seo.metaDescription }}</p>
                  <a [routerLink]="['/map', m.slug]" class="btn btn-primary">{{ 'VIEW' | translate }}</a>
               </div>
            </div>
         </div>
      </div>
      <div class="md-container">
         <div [innerHTML]="page_content_rendered" class="page-content"></div>
      </div>
   </div>
</div>
