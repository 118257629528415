<button 
   (click)="onZoomIn()"
   type="button" 
   placement="left" 
   ngbTooltip="{{'MAP.ZOOM_IN' | translate}}"
>
    <svg
       aria-hidden="true"
       focusable="false"
       data-prefix="fas"
       data-icon="plus"
       class="svg-inline--fa fa-plus fa-w-14"
       role="img"
       xmlns="http://www.w3.org/2000/svg"
       viewBox="0 0 448 512"
       width="20"
       height="20"
    >
       <path
          fill="currentColor"
          d="M416 208H272V64c0-17.67-14.33-32-32-32h-32c-17.67 0-32 14.33-32 32v144H32c-17.67 0-32 14.33-32 32v32c0 17.67 14.33 32 32 32h144v144c0 17.67 14.33 32 32 32h32c17.67 0 32-14.33 32-32V304h144c17.67 0 32-14.33 32-32v-32c0-17.67-14.33-32-32-32z"
       ></path>
    </svg>
 </button>
 <button 
   (click)="onZoomOut()"
   type="button" 
   placement="left" 
   ngbTooltip="{{'MAP.ZOOM_OUT' | translate}}"
 >
    <svg
       width="20"
       height="20"
       aria-hidden="true"
       focusable="false"
       data-prefix="fas"
       data-icon="minus"
       class="svg-inline--fa fa-minus fa-w-14"
       role="img"
       xmlns="http://www.w3.org/2000/svg"
       viewBox="0 0 448 512"
    >
       <path
          fill="currentColor"
          d="M416 208H32c-17.67 0-32 14.33-32 32v32c0 17.67 14.33 32 32 32h384c17.67 0 32-14.33 32-32v-32c0-17.67-14.33-32-32-32z"
       ></path>
    </svg>
 </button>