import { AfterViewInit, Component, EventEmitter, Inject, Input, OnChanges, OnDestroy, OnInit, Output, PLATFORM_ID, SimpleChanges } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { AbstractComponent, SnackbarService, MetaService } from '@sciamlab/ng-common';
import { StrapiService } from '@sciamlab/ng-common-strapi';
import { Subscription } from 'rxjs';
import { InfoboxService } from './infobox.service';
import { Options, ChangeContext } from '@angular-slider/ngx-slider';
import { isPlatformBrowser } from '@angular/common';

@Component({
   selector: 'infobox',
   templateUrl: './infobox.component.html',
   styleUrls: ['./infobox.component.scss'],
})
export class InfoboxComponent extends AbstractComponent implements OnInit, OnDestroy {
   @Input() model!: any;
   @Input() all_themes: any[] = [];
   @Input() selected_features: { [key: string]: any[] } = {};
   @Input() current_view!: number;
   @Input() selected_layer!: { [key: string]: any };
   @Input() selected_theme!: { [key: string]: any };
   // @Input() selected_theme_layers!: { [key: string]: {name: string, group: boolean}[] };
   @Input() selected_filter: { [key: string]: { [key: string]: any } } = {};
   @Input() filters: { [key: string]: any[] } = {};
   @Input() clicked!: boolean;
   @Input() loading_layers!: boolean;
   @Input() tiles_loading: { [key: string]: number } = {}
   @Input() tiles_loaded: { [key: string]: number } = {}
   @Input() tiles_progress = [0,0]

   @Output() onChangeView = new EventEmitter<number>();
   // @Output() onChangeMapLayer = new EventEmitter<[number,any]>();
   @Output() onChangeMapTheme = new EventEmitter<[number, any]>();
   @Output() onChangeFilter = new EventEmitter<[number, any, any, boolean]>();
   @Output() onChangeOpacity = new EventEmitter<number>();

   Object = Object;
   Array = Array;
   langChangeSubscription!: Subscription;
   toggle = false;
   page_content_rendered!: SafeHtml;

   legends: { [key: string]: string } = {};
   isLayerAvailable: { [key: string]: { [key: string]: { [key: string]: boolean}}} = {};
   isFilterOptionValid: { [key: string]: { [key: string]: { [key: string]: boolean}}} = {};

   VIEWS = [1,2,4];
   MONTHS = 'months';
   updateFilterAvailabilitySubscription!: Subscription;

   opacity;
   opacity_options: Options = {
      floor: 0,
      ceil: 1,
      step: 0.01,
      showSelectionBar: true
   };

   slider_value = 1;
   slider_playing = false;
   slider_interval!: any;
   slider_options: Options = {
      floor: 1,
      ceil: 12,
      step: 1,
      showTicks: true,
      // showTicksValues: true,
      // translate: (value: number): string => {
      //    return value.toString();
      // },
      ticksTooltip: (v: number): string => {
         return this.translate.instant('FILTER.months.' + v.toString().padStart(2, '0'));
      }
   };
   isCollapsed = true;
   collapseLegend: any = {};
   settings: any;
   isPlatformBrowser: boolean;

   constructor(
      route: ActivatedRoute,
      router: Router,
      snackbarService: SnackbarService,
      translate: TranslateService,
      protected infoboxService: InfoboxService,
      public metaService: MetaService,
      protected strapi: StrapiService,
      public sanitizer: DomSanitizer,
      @Inject(PLATFORM_ID) private platformId: any,
   ) {
      super(route, router, snackbarService, translate);
      this.isPlatformBrowser = isPlatformBrowser(this.platformId);
      this.settings = this.strapi.getSettings();
      this.opacity = this.settings.layer_default_opacity || 1;
   }

   async ngOnInit(): Promise<void> {
      this.updateFilterAvailabilitySubscription = this.infoboxService.onUpdateFilter.subscribe(({view, selected_filter, isFilterOptionValid, isLayerAvailable, legend}) => {
         // console.log('update filter', view, selected_filter, isFilterOptionValid, isLayerAvailable);
         
         if (view==1 && selected_filter[this.MONTHS]) {
            this.slider_value = +(this.filters[this.MONTHS].find(fv=>fv.name==selected_filter[this.MONTHS].name).name);
         }

         if (!this.isFilterOptionValid[view.toString()]) this.isFilterOptionValid[view.toString()] = {};
         this.isFilterOptionValid[view.toString()] = isFilterOptionValid;
         if (!this.isLayerAvailable[view.toString()]) this.isLayerAvailable[view.toString()] = {};
         this.isLayerAvailable[view.toString()] = isLayerAvailable;
         this.legends[view.toString()] = legend;
      });

      // loader nella veletta in corrispondenza del valore loading_layers=true 
   }
   
   sanitizeHTML(data: string) {
      return this.page_content_rendered = this.sanitizer.bypassSecurityTrustHtml(this.translate.instant(`HELPER_FILTER.${data}`))
   }

   changeView(view: number) {
      this.onChangeView.emit(view);
   }
   changeMapTheme(view: number, theme: any) {
      this.onChangeMapTheme.emit([view, theme]);
   }
   changeFilter(view: number, filter: { name: string }, value: { name: string }) {
      if (filter.name == this.MONTHS){
         if (view==1) this.slider_value = +(value.name);
         this.slider_pause();
      }
      this.onChangeFilter.emit([view, filter, value, this.isLayerAvailable[view.toString()][filter.name][value.name]]);
   }

   printThemeTitle(t: any): string {
      // return (t.parent ? `${this.printThemeTitle(t.parent)} > ` : '') + (t['title_'+this.translate.currentLang] || t.title)
      return (t.parent ? `${t.parent.title} > ` : '') + this.translate.instant(`THEME.${t.name}`);
   }

   ngOnDestroy() {
      if (this.langChangeSubscription) this.langChangeSubscription.unsubscribe();
      if (this.updateFilterAvailabilitySubscription) this.updateFilterAvailabilitySubscription.unsubscribe();
   }

   hasTranslation(key: string): boolean {
      const translation = this.translate.instant(key);
      return translation !== key && translation !== '';
   }

   slider_play() {
      this.slider_playing = true;
      this.slider_interval = setInterval(this._slide, this.settings.slider_interval || 2000, this);
      // this._slide(this);
   }
   private _slide(self: InfoboxComponent) {
      console.log(self.tiles_progress);
      
      if (self.tiles_progress[0]!=self.tiles_progress[1]) return;
      // if (!self.slider_playing) return;
      const current_value = self.filters[self.MONTHS].find(v=>v.name==self.selected_filter['1'][self.MONTHS].name)
      let next_index = self.filters[self.MONTHS].indexOf(current_value)+1;
      if (next_index == self.filters[self.MONTHS].length) next_index = 0; // questo per ricominciare il giro
      if (next_index==0) next_index = 1; // questo per evitare la selezione dell'annual
      self.slider_value = +(self.filters[self.MONTHS][next_index].name);
      self.onSliderChange(self.filters[self.MONTHS][next_index] as ChangeContext);
   }
   slider_pause() {
      this.slider_playing = false;
      clearInterval(this.slider_interval);
   }
   onSliderChange(changeContext: any): void {
      const value = this.filters[this.MONTHS].find(v=>v.name==(changeContext.name || changeContext.value))
      Array(this.current_view).fill(1).forEach((view,i)=>{
         ++i;
         if (!this.selected_filter[i.toString()] 
            || !this.selected_filter[i.toString()][this.MONTHS] 
            || this.getMonthsNumber(i)<12) return;
         this.selected_filter[i.toString()][this.MONTHS] = value;
         this.onChangeFilter.emit([i, {name: this.MONTHS}, value, this.isLayerAvailable[i.toString()][this.MONTHS][value.name]]);
      })
   }

   getMonthsNumber(view: number){
      if (!this.isFilterOptionValid[view.toString()] || !this.isFilterOptionValid[view.toString()][this.MONTHS]) return 0      
      return Object.values(this.isFilterOptionValid[view.toString()][this.MONTHS]).filter(fv=>fv).length
   }

   findUnit(map: number, selected_theme: string) {
      const t = this.all_themes.find((t: any) => t.name === selected_theme)
      const unit_custom = t.unit_custom ? t.unit_custom.split(',').map((uc: string) => uc.split(':')) : null
      let unit: any

      if (unit_custom) {
         for (const [index, value] of unit_custom.entries()) {         
            if (this.selected_filter[map] && this.selected_filter[map][unit_custom[index][0]] && this.selected_filter[map][unit_custom[index][0]].name == unit_custom[index][1]) {         
               unit = unit_custom[index][2]
               break
            }
         }
   
         return unit      
      } else {
         return unit = t?.unit
      }
   }

   formatValue(value: any): string {
      return value.toFixed(2)
   }

   onOpacityChange(changeContext: any): void {
      this.onChangeOpacity.emit(this.opacity);
   }
}
