<div class="d-flex">
   <div class="d-none d-lg-block flex-shrink-0 infobox-div">
      <!-- DESKTOP -->
      <ng-template [ngTemplateOutlet]="infobox"></ng-template>
   </div>
   <div (window:resize)="onResize($event)" class="homepage w-100">
      <div class="mapbox-container" *ngIf="isPlatformBrowser && view">
         <div *ngFor="let n of Array(current_view).fill(1); let i = index" class="mapbox mapbox-{{ current_view }}">
            <span *ngIf="current_view > 1" class="badge rounded-pill bg-primary map-badge">
               <h3 class="m-0">{{i + 1}}</h3>
            </span>
            <ng-map
               *ngIf="render"
               [target]="'map' + (i + 1)"
               [view]="view"
               [layers]="layers[(i + 1).toString()]"
               (onMap)="onMap($event, (i + 1))"
            ></ng-map>
            <ng-map-control-attribution
               *ngIf="maps['map' + (i + 1)]"
               [map]="maps['map' + (i + 1)]"
               [options]="{ collapsible: false }"
            ></ng-map-control-attribution>
            <div class="d-block d-lg-none">
               <!-- MOBILE -->
               <div class="{{ clicked ? 'open' : '' }} infobox-slider d-block d-lg-none">
                  <div>
                     <div (click)="toggle()" class="icon-bar-div">
                        <span class="icon-bar d-block d-lg-none"></span>
                     </div>
                     <div class="px-3 value-div">
                        <ng-template [ngTemplateOutlet]="infobox"></ng-template>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </div>

      <div *ngIf="maps">
         <div class="icon-div d-flex flex-column justify-content-between" *ngIf="isPlatformBrowser && Object.keys(maps).length">
            <ng-map-control-zoom [maps]="maps"></ng-map-control-zoom>
            <ng-map-control-baselayer [maps]="maps" [base_layers]="base_layers"></ng-map-control-baselayer>
            <ng-map-control-geolocation [maps]="maps"></ng-map-control-geolocation>
            <div class="d-flex" style="max-height: 44px;">
               <div style="height: 100%;" class="collapse sidebar" id="collapseExample">
                  <ngx-slider *ngIf="isPlatformBrowser" [(value)]="opacity" [options]="opacity_options" (userChange)="onOpacityChange($event)" class="opacity-slider"></ngx-slider>
               </div>
               <button 
                  class="btn btn-primary {{ opacity_toggler_clicked ? 'slider-button-clicked' : 'slider-button-not-clicked'}}" 
                  type="button" 
                  (click)="opacity_toggler_clicked = !opacity_toggler_clicked"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseExample" 
                  data-target="#collapseExample" 
                  aria-expanded="false" 
                  aria-controls="collapseExample"
                  style="height: 100%;"
                  type="button" 
                  placement="left" 
                  ngbTooltip="{{'MAP.OPACITY_SLIDER' | translate}}"
               >
               <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-eye" viewBox="0 0 16 16">
                  <path d="M16 8s-3-5.5-8-5.5S0 8 0 8s3 5.5 8 5.5S16 8 16 8zM1.173 8a13.133 13.133 0 0 1 1.66-2.043C4.12 4.668 5.88 3.5 8 3.5c2.12 0 3.879 1.168 5.168 2.457A13.133 13.133 0 0 1 14.828 8c-.058.087-.122.183-.195.288-.335.48-.83 1.12-1.465 1.755C11.879 11.332 10.119 12.5 8 12.5c-2.12 0-3.879-1.168-5.168-2.457A13.134 13.134 0 0 1 1.172 8z"/>
                  <path d="M8 5.5a2.5 2.5 0 1 0 0 5 2.5 2.5 0 0 0 0-5zM4.5 8a3.5 3.5 0 1 1 7 0 3.5 3.5 0 0 1-7 0z"/>
                </svg>
               </button>
            </div>
         </div>
      </div>
   </div>
</div>

<ng-template #infobox>
   <infobox
      [model]="model"
      [all_themes]="all_themes"
      [filters]="filters"
      [selected_features]="selected_features"
      [current_view]="current_view"
      [selected_layer]="selected_layer"
      [selected_theme]="selected_theme"
      [selected_filter]="selected_filter"
      [loading_layers]="loading_layers"
      [tiles_loaded]="tiles_loaded"
      [tiles_loading]="tiles_loading"
      [tiles_progress]="tiles_progress"
      (onChangeView)="setVista($event)"
      (onChangeMapTheme)="onChangeMapTheme($event)"
      (onChangeFilter)="onChangeFilter($event)"
      (onChangeOpacity)="onChangeOpacity($event)"
      [clicked]="clicked"
   ></infobox>
</ng-template>
