import { Component, Inject, OnInit, PLATFORM_ID } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { MetaService, SnackbarService } from '@sciamlab/ng-common';
import { SingleTypeComponent, StrapiService } from '@sciamlab/ng-common-strapi';
import { DomSanitizer, SafeHtml, SafeUrl } from '@angular/platform-browser';

@Component({
   selector: 'app-documentation',
   templateUrl: './documentation.component.html',
   styleUrls: ['./documentation.component.scss'],
})
export class DocumentationComponent extends SingleTypeComponent implements OnInit {
   src: SafeUrl;
   page_content_rendered_article!: SafeHtml;
   page_content_rendered_brochure!: SafeHtml;
   page_content_rendered_tutorial!: SafeHtml;

   constructor(
      route: ActivatedRoute,
      router: Router,
      snackbarService: SnackbarService,
      translate: TranslateService,
      metaService: MetaService,
      strapi: StrapiService,
      private sanitizer: DomSanitizer,
   ) {
      super(route, router, snackbarService, translate, metaService, strapi);
      const url = this.model.video.link;
      this.src = this.sanitizer.bypassSecurityTrustResourceUrl(url);
   }

   async ngOnInit(): Promise<void> {
      await super.ngOnInit();
      
      this.page_content_rendered_article = this.sanitizer.bypassSecurityTrustHtml(this.model.article.text)
      this.page_content_rendered_brochure = this.sanitizer.bypassSecurityTrustHtml(this.model.brochure.text)
      this.page_content_rendered_tutorial = this.sanitizer.bypassSecurityTrustHtml(this.model.tutorial.text)
   }
}
