<ngx-json-ld *ngIf="meta_enabled" [json]="webpage"></ngx-json-ld>

<div *ngIf="this.model.cover.data" class="page-view-banner img-fluid" style="background-image:url('{{ getCoverUrl() }}');">
   <div class="cont"></div>
</div>
<div class="component page-view" *ngIf="model">
   <div class="container-fluid">
      <div class="justify-content-between d-block d-lg-flex pt-4 md-container col-12 col-lg-9">
         <section>
            <h1>{{ model.seo.metaTitle }}</h1>
            <div [innerHTML]="page_content_rendered" class="page-content"></div>
         </section>
      </div>
   </div>
</div>
