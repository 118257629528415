import { Component, Injectable, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, ActivatedRouteSnapshot, Resolve, Router, RouterStateSnapshot } from '@angular/router';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { StrapiService } from '@sciamlab/ng-common-strapi';
import { environment } from 'src/environments/environment';
import { AbstractComponent, MetaService, SnackbarService } from '@sciamlab/ng-common';

@Component({
   selector: 'app-nav',
   templateUrl: './nav.component.html',
   styleUrls: ['./nav.component.scss'],
})
export class NavComponent extends AbstractComponent implements OnInit, OnDestroy {
   model: any;
   settings: any;
   langChangeSubscription!: Subscription;
   environment = environment;
   langs: string[] = [];
   public gfg = true;
   clicked = false;
   maps: any = [];

   constructor(
      route: ActivatedRoute,
      router: Router,
      snackbarService: SnackbarService,
      translate: TranslateService,
      public metaService: MetaService,
      protected strapi: StrapiService,
   ) {
      super(route, router, snackbarService, translate);
   }

   async ngOnInit(): Promise<void> {
      this.settings = this.strapi.getSettings();
      this.langs = this.translate.getLangs();
      console.log('langs', this.langs);

      this.langChangeSubscription = this.translate.onLangChange.subscribe(async (event: LangChangeEvent) => {
         this.model = await this.strapi.getSingleType('main-menu', this.translate.currentLang, 'items.routing');
         this.maps = (await this.strapi.filterCollectionType(
            'maps',
            undefined,
            undefined,
            undefined,
            undefined,
            this.translate.currentLang,
            'layers.options,base_layers.options,seo.metaImage',
         )).data;
      });

      this.model = await this.strapi.getSingleType('main-menu', this.translate.currentLang, 'items.routing');
      this.maps = (await this.strapi.filterCollectionType(
         'maps',
         undefined,
         undefined,
         undefined,
         undefined,
         this.translate.currentLang,
         'layers.options,base_layers.options,seo.metaImage',
      )).data;
   }

   useLanguage(lang: string): void {
      this.translate.use(lang);
      // this.router.routeReuseStrategy.shouldReuseRoute = () => false;
      // this.router.onSameUrlNavigation = 'reload';
      this.router.navigate([this.router.url.split('?')[0]], { queryParams: this.route.snapshot.queryParams });
   }

   getCoverUrl(): string {
      return this.strapi.getImageUrl(this.settings.logo.url);
   }

   closeNavMenu(): boolean {
      return (this.clicked = false);
   }

   navigate(slug: string) {
      this.router.navigate(['/map', slug]);
   }

   ngOnDestroy() {
      if (this.langChangeSubscription) this.langChangeSubscription.unsubscribe();
   }
}
