import { Component, Inject, OnInit, PLATFORM_ID } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { MetaService, SnackbarService } from '@sciamlab/ng-common';
import { StrapiService, SingleTypeComponent } from '@sciamlab/ng-common-strapi';

@Component({
   selector: 'app-contacts',
   templateUrl: './contacts.component.html',
   styleUrls: ['./contacts.component.scss'],
})
export class ContactsComponent extends SingleTypeComponent implements OnInit {
   public message!: string;
   loading = true;
   settings: any;
   lat!: number;
   lon!: number;

   constructor(
      route: ActivatedRoute,
      router: Router,
      strapi: StrapiService,
      translate: TranslateService,
      meta: MetaService,
      snackbarService: SnackbarService,
   ) {
      super(route, router, snackbarService, translate, meta, strapi);
   }

   async ngOnInit(): Promise<void> {
      await super.ngOnInit();

      this.webpage = {
         ...this.webpage,
         '@type': 'ContactPage',
      };
   }
}
