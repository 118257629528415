import { registerLocaleData } from '@angular/common';
import localeEn from '@angular/common/locales/en';
import localeIt from '@angular/common/locales/it';
import { environment } from './environments/environment';

registerLocaleData(localeEn, 'en');
registerLocaleData(localeIt, 'it');

export const CONFIG = {
   sandbox: null,
   date_format: 'dd/MM/yyyy H:mm:ss',
   snackbar: {
      duration: 5000,
   },
   // translate: {
   //    langs: ['en', 'it'],
   //    default: 'it',
   // },
   gravatar: {
      url: 'https://www.gravatar.com/avatar/',
      options: '?d=identicon',
   },
   web: {
      user: {
         logout: '/logout',
         login: '/login',
         register: '/register',
         me: '/user/me',
         reset_password_email: '/user/password/reset',
      },
   },
   api: {
      url: environment.cms,
      user: {
         url: environment.cms+'/api',
         login: '/auth/local',
         register: '/auth/local/register',
         filter: '/user/filter',
         all: '/user',
         me: '/users/me',
         id: '/users/:id',
         update: '/user/:id',
         delete: '/user/:id',
         update_state: '/user/:id/state/:state',
         update_email: '/user/:id/email',
         update_group: '/user/group',
         delete_group: '/user/group',
         update_state_group: '/user/group/state/:state',
         create: '/user',
         is_email_busy: '/user/is_email_busy',
         reset_password_email: '/auth/forgot-password',
         reset_password_confirmation: '/auth/reset-password',
         verify_email: '/user/verify/:email_confirmation_token',
      },
   },
   dataset: {
      url: '/datasets',
   },
   vocabularies: {},
};
