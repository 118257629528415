import { APP_INITIALIZER, ErrorHandler, Injectable, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClient, HttpClientModule, HttpErrorResponse } from '@angular/common/http';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NavComponent } from './components/nav/nav.component';
import { FooterComponent } from './components/footer/footer.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ContactsComponent } from './components/contacts/contacts.component';
import { PageViewComponent } from './components/page-view/page-view.component';
import { NewsListComponent } from './components/news-list/news-list.component';
import { NewsViewComponent } from './components/news-view/news-view.component';
import { ErrorComponent } from './components/error/error.component';
import { SwiperModule } from 'swiper/angular';
import { FormsModule } from '@angular/forms';
import { TransferHttpCacheModule } from '@nguniversal/common';
import { NgMapComponent } from './components/ng-map/ng-map.component';
import { NewsCardComponent } from './components/news-card/news-card.component';
import { ControlGeolocationComponent } from './components/ng-map/control-geolocation/control-geolocation.component';
import { ControlZoomComponent } from './components/ng-map/control-zoom/control-zoom.component';
import { ControlAttributionComponent } from './components/ng-map/control-attibution/control-attibution.component';
import { ControlBaselayerComponent } from './components/ng-map/control-baselayer/control-baselayer.component';
import { NgSelectModule } from '@ng-select/ng-select';
// import { NgxJsonLdModule } from '@ngx-lite/json-ld';
import {
   NgCommonStrapiModule,
   StrapiService,
   StrapiTranslateInitService,
   StrapiV4MetaService,
   StrapiV4Service,
   StrapiTranslateLoader,
} from '@sciamlab/ng-common-strapi';
import { environment } from 'src/environments/environment';
import { AppInitService, CookieService, MetaService, NgCommonModule, NgxJsonLdModule, TranslateInitService } from '@sciamlab/ng-common';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { CONFIG } from 'src/config';
import { DocumentationComponent } from './components/documentation/documentation.component';
import { HomeComponent } from './components/home/home.component';
import { MapComponent } from './components/map/map.component';
import * as package_json from '../../package.json';
import { InfoboxComponent } from './components/map/infobox/infobox.component';
import { NgxSliderModule } from '@angular-slider/ngx-slider';
import { NgOptimizedImage } from '@angular/common';
import { GoogleAnalyticsGtagComponent } from './components/google-analytics-gtag/google-analytics-gtag.component';

// import { StrapiV4Service } from './components/strapi-v4.service';
// import { StrapiV4MetaService } from './strapi-v4-meta.service';

/* Best practices for Error handling in Angular
 * https://medium.com/angular-in-depth/expecting-the-unexpected-best-practices-for-error-handling-in-angular-21c3662ef9e4
 */

@Injectable({ providedIn: 'root' })
export class CustomErrorHandler implements ErrorHandler {
   constructor() {
      // do nothing.
   }

   handleError(error: any) {
      /* Angular custom error handler not getting error type from promise
       * https://stackoverflow.com/a/54826258
       */
      if (error.promise && error.rejection) {
         // Promise rejection wrapped by zone.js
         error = error.rejection;
      }
      if (error.error) {
         error = error.error;
      }

      let message = null;
      const stackTrace = null;

      if (error instanceof HttpErrorResponse) {
         // Server Error
         message = error.message;
      } else {
         // Client Error
         message = error.message || error.toString();
      }

      //   this.snackbarService.error(message);

      // Always log errors
      // console.error(message, stackTrace);
      console.error(error);
   }
}

@NgModule({
   declarations: [
      AppComponent,
      NavComponent,
      FooterComponent,
      ContactsComponent,
      PageViewComponent,
      NewsListComponent,
      NewsViewComponent,
      ErrorComponent,
      NgMapComponent,
      InfoboxComponent,
      NewsCardComponent,
      ControlGeolocationComponent,
      ControlZoomComponent,
      ControlAttributionComponent,
      ControlBaselayerComponent,
      MapComponent,
      DocumentationComponent,
      HomeComponent,
      GoogleAnalyticsGtagComponent,
   ],
   imports: [
      NgxJsonLdModule,
      BrowserModule.withServerTransition({ appId: 'serverApp' }),
      AppRoutingModule,
      NgbModule,
      HttpClientModule,
      TranslateModule,
      SwiperModule,
      NgSelectModule,
      FormsModule,
      TransferHttpCacheModule,
      NgOptimizedImage,
      NgCommonStrapiModule.forRoot({
         strapi: {
            url: environment.cms,
            settings: {
               populate: 'seo.metaImage,favicon,base_layers.options,country_layer.options,griglia_layer.options,meta.image,funder_image.image'
            }
         },
      }),
      TranslateModule.forRoot({
         loader: {
            provide: TranslateLoader,
            useFactory: (strapi: StrapiService) => {
               return new StrapiTranslateLoader(strapi);
            },
            deps: [StrapiService],
         },
      }),
      NgCommonModule.forRoot({
         snackbar: CONFIG.snackbar,
         date_format: CONFIG.date_format,
         app: {
            package_json: {
               name: ((package_json as any).default || (package_json as any))?.name,
               version: ((package_json as any).default || (package_json as any))?.version,
            },
         },
         token_interceptor: {
            url: CONFIG.api.url,
         },
         // translate: CONFIG.translate || { langs: ['en'], default: 'en' },
      }),
      NgxSliderModule
   ],
   providers: [
      {
         provide: APP_INITIALIZER,
         useFactory: (appInitService: AppInitService) => {
            return (): Promise<any> => {
               return appInitService.init();
            };
         },
         multi: true,
         deps: [AppInitService],
      },
      {
         provide: ErrorHandler,
         useClass: CustomErrorHandler,
      },
      CookieService,
      {
         provide: TranslateInitService,
         useClass: StrapiTranslateInitService,
         deps: [CookieService],
      },
      {
         provide: APP_INITIALIZER,
         useFactory: (strapi: StrapiService, translateInitService: StrapiTranslateInitService) => {
            return async (): Promise<any> => {
               await strapi.init();
               return translateInitService.init();
            };
         },
         multi: true,
         deps: [StrapiService, StrapiTranslateInitService, CookieService],
      },
      {
         provide: StrapiService,
         useClass: StrapiV4Service,
      },
      {
         provide: MetaService,
         useClass: StrapiV4MetaService,
      },
   ],
   bootstrap: [AppComponent],
})
export class AppModule {}
