<nav class="navbar-component navbar bg-light navbar-expand-lg sticky-top py-2 border-bottom" *ngIf="model">
   <div class="container-fluid">
      <a routerLink="/" style="width: 150px;">
         <div style="background-image: url('{{ strapi.getImageUrl(settings.meta.image) }}');" class="logo"></div>
      </a>
      <button class="navbar-toggler" data-bs-toggle="collapse" data-bs-target="#navbar">
         <i class="burger-icon bi bi-list"></i>
      </button>
      <div class="navbar-collapse collapse justify-content-end nav-links" id="navbar">
         <ul *ngIf="model" class="navbar-nav mb-2 mb-lg-0 overlay-content justify-content-end">
            <li class="nav-item px-2 d-flex align-items-center" *ngFor="let item of model.items">
               <a class="nav-link active" *ngIf="item.routing.type == 'SINGLE_TYPE'" routerLink="/{{ item.routing.location }}">{{ item.routing.title }}</a>

               <a class="nav-link active" *ngIf="item.routing.type == 'PAGE'" [routerLink]="['/page', item.routing.location]">{{ item.routing.title }}</a>

               <a class="nav-link active" *ngIf="item.routing.type == 'NEWS'" [routerLink]="['/news', item.routing.location, item.id]">{{ item.routing.title }}</a>

               <a class="nav-link active" *ngIf="item.routing.type == 'URL'" target="_blank" routerLink="{{ item.routing.location }}">{{ item.routing.title }}</a>
            </li>
            <div class="dropdown d-flex align-items-center mx-1 mb-1">
               <button class="btn nav-btn btn-outline-primary dropdown-toggle w-100" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                  {{ 'SELECT_MAP' | translate }}
               </button>
               <ul class="dropdown-menu">
                  <li *ngFor="let map of maps"><a class="dropdown-item" (click)="navigate(map.slug)">{{ map.title }}</a></li>
               </ul>
            </div>
            <div class="dropdown d-flex align-items-center mx-1 mb-1" *ngIf="langs.length > 1">
               <button class="btn nav-btn btn-outline-primary dropdown-toggle w-100" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                  {{ translate.currentLang }}
                </button>
               <ul class="dropdown-menu">
                 <li *ngFor="let lang of translate.getLangs()"><a class="dropdown-item" (click)="useLanguage(lang)">{{ lang }}</a></li>
               </ul>
            </div>
         </ul>
      </div>
   </div>
</nav>
