import { isPlatformBrowser, isPlatformServer } from '@angular/common';
import { Component, ElementRef, Inject, Input, OnInit, PLATFORM_ID, Renderer2 } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';

declare let gtag: any;

@Component({
   selector: 'app-google-analytics-gtag',
   template: '',
})
export class GoogleAnalyticsGtagComponent implements OnInit {
   @Input('id') TRACKING_ID!: any;

   constructor(
      @Inject(PLATFORM_ID) private readonly platformId: any,
      private readonly renderer: Renderer2,
      private readonly el: ElementRef,
      private router: Router,
   ) {}

   async ngOnInit(): Promise<any> {
      // BROWSER
      if (isPlatformServer(this.platformId)) {
         const script = this.renderer.createElement('script') as HTMLScriptElement;
         script.src = `https://www.googletagmanager.com/gtag/js?id=${this.TRACKING_ID}`;
         script.async = true;
         this.renderer.appendChild(this.el.nativeElement, script);

         const script2 = this.renderer.createElement('script') as HTMLScriptElement;
         const scriptBody = this.renderer.createText(`
            window.dataLayer = window.dataLayer || [];
            function gtag() {
              dataLayer.push(arguments);
            }
            gtag('js', new Date());
    
            `);
         // gtag('config', '${this.TRACKING_ID}');
         this.renderer.appendChild(script2, scriptBody);
         this.renderer.appendChild(this.el.nativeElement, script2);
      } else {
         this.router.events.subscribe((event) => {
            if (event instanceof NavigationEnd) {
               console.log(event.urlAfterRedirects);

               gtag('config', this.TRACKING_ID, {
                  page_path: event.urlAfterRedirects,
               });
            }
         });
      }
   }
}
