<ngx-json-ld *ngIf="meta_enabled" [json]="webpage"></ngx-json-ld>

<div class="component documentation" *ngIf="model">
   <div class="container-fluid">
      <div *ngIf="model" class="justify-content-between d-block col-12 col-lg-9 pt-4 md-container">
         <h1>{{ model.seo.metaTitle }}</h1>
         <section class="mb-5">
            <h3>{{ model.article.title }}</h3>
            <div [innerHTML]="page_content_rendered_article" class="page-content"></div>
         </section>
   
         <section class="mb-5">
            <h3>{{ model.brochure.title }}</h3>
            <div [innerHTML]="page_content_rendered_brochure" class="page-content"></div>
         </section>
   
         <section class="mb-5">
            <h3>{{ model.tutorial.title }}</h3>
            <div [innerHTML]="page_content_rendered_tutorial" class="page-content"></div>
         </section>
   
         <section class="video-container">
            <iframe
               [src]="src"
               title="YouTube video player"
               class="documentation-video"
               frameborder="0"
               allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
               allowfullscreen
            ></iframe>
         </section>
      </div>
   </div>
</div>
