import { Injectable } from '@angular/core';
import { Feature, Map, MapBrowserEvent, MapEvent, View } from 'ol';
import TileLayer from 'ol/layer/Tile';
import OSM from 'ol/source/OSM';
import * as proj from 'ol/proj';
import * as extent from 'ol/extent';
import { Coordinate } from 'ol/coordinate';
import Geometry from 'ol/geom/Geometry';
import { Subject } from 'rxjs';
import { Layer } from 'ol/layer';
import { BingMaps, Source, Stamen, TileWMS, WMTS, XYZ } from 'ol/source';
import WMTSTileGrid from 'ol/tilegrid/WMTS';
import { Fill, Icon, Stroke, Style } from 'ol/style';
import Circle from 'ol/geom/Circle';
import MVT from 'ol/format/MVT';
import VectorTileLayer from 'ol/layer/VectorTile';
import VectorTileSource from 'ol/source/VectorTile';
import { ViewOptions } from 'ol/View';
import {defaults, MouseWheelZoom} from 'ol/interaction';
import {boundingExtent, Extent, getCenter} from 'ol/extent';
import {fromLonLat} from 'ol/proj';
@Injectable({
  providedIn: 'root'
})
export class NgMapService {

  private mouseClickSource = new Subject<{ map: Map, features: Feature<Geometry>[] }>();
  mouseClick$ = this.mouseClickSource.asObservable();

  private mapMoveSource = new Subject<{ map: Map, event: MapEvent }>();
  mapMove$ = this.mapMoveSource.asObservable();

  private pointerMoveSource = new Subject<{ map: Map, features: Feature<Geometry>[] }>();
  pointerMove$ = this.pointerMoveSource.asObservable();

  constructor() {
    // empty
  }

  createView(center: number[] = [0, 0], zoom = 1, minZoom?:number, maxZoom?: number): View {
    const options: ViewOptions = {
      center: proj.transform([center[1], center[0]], 'EPSG:4326', 'EPSG:3857'),
      zoom,
    };
    if (minZoom) options.minZoom = minZoom;
    if (maxZoom) options.maxZoom = maxZoom;
    return new View(options)
  }

  create(target: string, options: {
    view?: View,
    center?: number[],
    zoom?: number,
    controls?: any,
    layers?: any[],
  } = {}): Map {
    const map = new Map({
      view: options.view || new View({
        center: proj.transform(options.center ? [options.center[1], options.center[0]] : [0, 0], 'EPSG:4326', 'EPSG:3857'),
        zoom: options.zoom || 1,
      }),
      layers: options.layers || [
        new TileLayer({
          source: new OSM(),
        }),
      ],
      controls: options.controls || [],
      // interactions: defaults({mouseWheelZoom: false}).extend([ new MouseWheelZoom({
      //   constrainResolution: true // force zooming to a integer zoom 
      // }) ]),
      target
    });

    return map;
  }

  setLayer(map: Map, layer: any, i?: number){
    if(i!=null)
      map.removeLayer(map.getLayers().getArray()[i]);
    if (layer)
      map.getLayers().insertAt(i!=null ? i : map.getLayers().getArray().length, LayerFactory.build(layer.type, layer.options));
  }

  getCoordinates(map: Map): { center: number[], zoom: number } {
    const view: View = map.getView();
    const center = proj.toLonLat(view.getCenter() as number[], view.getProjection()).reverse();
    // aggiungo o sovrascrivo il parametro @
    return { center, zoom: Math.floor(view.getZoom() as number) };
  }

  goToExtent(map: Map, _extent: number[][], animate = true, duration = 1000): void {
    const extent: Extent = boundingExtent([
      fromLonLat(_extent[0], map.getView().getProjection()),
      fromLonLat(_extent[1], map.getView().getProjection())
    ]);
    const center: Coordinate = getCenter(extent);

    const view = map.getView();
    const resolution = view.getResolutionForExtent(extent);
    const zoom = view.getZoomForResolution(resolution) as number;

    this.goToCoordinate(map, center, zoom, animate, duration);
  }

  goToCoordinate(map: Map, center: Coordinate, zoom: number, animate = true, duration = 1000): void {
    zoom = Math.round(zoom);
    const view = map.getView();
    if (animate) {
      view.animate({ center, zoom, duration });
    } else {
      view.setZoom(zoom);
      view.setCenter(center);
    }
  }

  goToCenter(map: Map, _center: number[], zoom: number, animate = true, duration = 1000): void {
    const center: Coordinate = proj.fromLonLat(_center, map.getView().getProjection());
    this.goToCoordinate(map, center, zoom, animate, duration);
  }

  zoomIn(map: Map, animate = true, duration = 1000): void {
    this.zoomAt(map, (map.getView().getZoom() || 0) + 1, animate, duration);
  }

  zoomOut(map: Map, animate = true, duration = 1000): void {
    this.zoomAt(map, (map.getView().getZoom() || 0) - 1, animate, duration);
  }

  zoomAt(map: Map, zoom: number, animate = true, duration = 1000): void {
    zoom = Math.round(zoom);
    if (animate) {
      map.getView().animate({ zoom, duration });
    } else {
      map.getView().setZoom(zoom);
    }
  }
}



export class LayerFactory {

  public static XYZ(options: any = {}): TileLayer<OSM> {
    return LayerFactory.build('xyz', options) as TileLayer<OSM>;
  }
  public static WMS(options: any = {}): TileLayer<OSM> {
    return LayerFactory.build('wms', options) as TileLayer<OSM>;
  }
  public static OSM(options: any = {}): TileLayer<OSM> {
    return LayerFactory.build('osm', options) as TileLayer<OSM>;
  }
  public static BING(options: any = {}): TileLayer<BingMaps> {
    return LayerFactory.build('bing', options) as TileLayer<BingMaps>;
  }
  public static STAMEN(options: any = {}): TileLayer<BingMaps> {
    return LayerFactory.build('stamen', options) as TileLayer<BingMaps>;
  }

  public static build(type: string, options: any = {}): Layer<Source> {
    const _options = Object.assign({}, options);
    if (_options.minZoom == null) delete _options.minZoom
    if (_options.maxZoom == null) delete _options.maxZoom
    switch (type) {
      case 'xyz':
        _options.source = new XYZ(_options.source);
        return new TileLayer(_options);
        break;

      case 'osm':
        _options.source = new OSM(_options.source || {});
        return new TileLayer(_options);
        break;

      case 'bing':
        _options.source = new BingMaps(_options.source || {});
        return new TileLayer(_options);
        break;

      case 'stamen':
        _options.source = new Stamen(_options.source);
        return new TileLayer(_options);
        break;

      case 'wms':
        _options.source = new TileWMS(_options.source);
        return new TileLayer(_options);
        break;

      case 'wmts':
        _options.source = Object.assign({}, _options.source);
        _options.source.projection = proj.get(_options.source.projection);
        _options.source.tileGrid = new WMTSTileGrid({
          origin: extent.getTopLeft(_options.source.projection.getExtent())
          , ..._options.source.tileGrid
        });
        _options.source = new WMTS(_options.source);
        return new TileLayer(_options);
        break;

      case 'mvt':
        if (_options.style && !(_options.style instanceof Function)) {
          _options.style = Object.assign({}, _options.style);
          // Stroke
          if (_options.style.stroke) {
            _options.style.stroke = new Stroke(_options.style.stroke);
          }
          // Fill
          if (_options.style.fill) {
            _options.style.fill = new Fill(_options.style.fill);
          }
          // Icon
          if (_options.style.icon) {
            _options.style.image = new Icon(_options.style.icon);
            delete _options.style.icon;
          }
          // CirleStyle
          if (_options.style.circle) {
            _options.style.circle = Object.assign({}, _options.style.circle);
            if (_options.style.circle.stroke) {
              _options.style.circle.stroke = new Stroke(_options.style.circle.stroke);
            }
            if (_options.style.circle.fill) {
              _options.style.circle.fill = new Fill(_options.style.circle.fill);
            }
            _options.style.image = new Circle(_options.style.circle);
            delete _options.style.icon;
          }
          _options.style = new Style(_options.style);
        }

        _options.source = Object.assign({ format: new MVT() }, _options.source);
        // // scommentare per riabilitare l'uso del tileGrid
        // if (_options.source.tileGrid) {
        //   _options.source.tileGrid = Object.assign({}, _options.source.tileGrid);
        //   if (_options.source.tileGrid.scales) {
        //     // qui si converte l'array dai valori di scale a quelli di resolution
        //     _options.source.tileGrid.resolutions = [];
        //     for (const s of _options.source.tileGrid.scales) {
        //       _options.source.tileGrid.resolutions.push(
        //         NgMapService.getResolutionFromScale(s, getProjection(_options.source.projection || 'EPSG:3857').getUnits())
        //       );
        //     }
        //     delete _options.source.tileGrid.scales;
        //   }
        //   _options.source.tileGrid = new TileGrid(_options.source.tileGrid);
        // }
        _options.source = new VectorTileSource(_options.source);
        return new VectorTileLayer(_options);
        break;

      default:
        throw new Error('Layer type not recognized: ' + type);
        break;
    }
  }
}

