
import { Component, OnInit, Input } from '@angular/core';
import Map from 'ol/Map';
import Geolocation from 'ol/Geolocation';
import Feature from 'ol/Feature';
import { Vector as VectorLayer } from 'ol/layer';
import { Vector as VectorSource } from 'ol/source';
import Point from 'ol/geom/Point';
import { Circle as CircleStyle, Fill, Stroke, Style } from 'ol/style';
import Geometry from 'ol/geom/Geometry';
import { NgMapService } from '../ng-map.service';

@Component({
  selector: 'ng-map-control-geolocation',
  templateUrl: './control-geolocation.component.html',
  styleUrls: ['./control-geolocation.component.scss']
})
export class ControlGeolocationComponent implements OnInit {

  @Input() maps: { [key: string]: Map } = {};
  @Input() options: any = {};
  // @Input() zoom: number;
  geolocation!: Geolocation;
  positionFeature: Feature<Point> = new Feature();
  vector: VectorLayer<VectorSource<Geometry>> = new VectorLayer({
    source: new VectorSource({
      features: [this.positionFeature]
    }),
    style: new Style({
      image: new CircleStyle({
        radius: 12,
        stroke: new Stroke({
          color: 'rgba(255, 255, 255, 0.8)',
          width: 6
        }),
        fill: new Fill({
          color: 'rgba(0, 102, 204, 0.8)'
        })
      })
    })
  });

  active = false;

  constructor(protected mapService: NgMapService) { }

  ngOnInit() {
    // noop
  }

  toggleGeolocate() {
    this.geolocation = new Geolocation(Object.assign({ projection: Object.values(this.maps)[0].getView().getProjection() }, this.options));

    this.geolocation.on('change:position', () => {
      const center = this.geolocation.getPosition();
      console.log('position', center);
      this.geolocation.setTracking(false);
      if (center) this.mapService.goToCoordinate(Object.values(this.maps)[0], center, 13 || Object.values(this.maps)[0].getView().getMaxZoom());
    });

    this.geolocation.on('error', error => {
      console.error(error);
    });

    this.geolocation.on('change:position', () => {
      const center = this.geolocation.getPosition();
      if (center) this.positionFeature.setGeometry(new Point(center));
    });

    this.active = !this.active;
    if (this.active) {
      this.onGeolocate();
    } else {
      this.stopGeolocate();
    }
  }

  onGeolocate() {
    this.geolocation.setTracking(true);
    Object.values(this.maps).forEach(map => {
      map.addLayer(this.vector);
    });
  }

  stopGeolocate() {
    this.geolocation.setTracking(false);
    Object.values(this.maps).forEach(map => {
      map.removeLayer(this.vector);
    });
  }
}
