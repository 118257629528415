import { Component, Injectable, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Resolve, Router } from '@angular/router';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { StrapiService, StrapiV4MetaService, CollectionTypeComponent } from '@sciamlab/ng-common-strapi';
import { SwiperOptions } from 'swiper';
import SwiperCore, { Pagination, Navigation } from 'swiper';
import { environment } from 'src/environments/environment';
import * as luxon from 'luxon';
import { MetaService, SnackbarService } from '@sciamlab/ng-common';

SwiperCore.use([Pagination, Navigation]);

@Component({
   selector: 'app-news-view',
   templateUrl: './news-view.component.html',
   styleUrls: ['./news-view.component.scss'],
})
export class NewsViewComponent extends CollectionTypeComponent implements OnInit {
   @ViewChild('swiper', { static: false }) swiper?: any;
   news: any[] = [];

   environment = environment;
   luxon = luxon;

   config: SwiperOptions = {
      slidesPerView: 1,
      navigation: false,
      pagination: false,
      spaceBetween: 20,
      scrollbar: { draggable: true },
      breakpoints: {
         '768': {
            slidesPerView: 3,
            spaceBetween: 0,
         },
         '1024': {
            slidesPerView: 3,
            spaceBetween: 0,
         },
      },
   };

   slideNext() {
      this.swiper.swiperRef.slideNext(100);
   }

   slidePrev() {
      this.swiper.swiperRef.slidePrev(100);
   }

   constructor(
      route: ActivatedRoute,
      router: Router,
      strapi: StrapiService,
      snackbarService: SnackbarService,
      meta: MetaService,
      translate: TranslateService,
   ) {
      super(route, router, snackbarService, translate, meta, strapi);
   }

   async ngOnInit(): Promise<void> {
      await super.ngOnInit();

      this.news = await this.strapi.filterNews(undefined, 0, 3, 'published_at:DESC', this.translate.currentLang);
   }

   getCoverUrl() {
      return this.strapi.getImageUrl(this.model.cover.url);
   }
}
