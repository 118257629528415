<ngb-progressbar
   [value]="(tiles_progress[0] / tiles_progress[1]) * 100"
   [striped]="slider_playing"
   [animated]="slider_playing"
></ngb-progressbar>
<div class="py-1 px-3 d-flex flex-column infobox-div-parent component">
   <div *ngIf="loading_layers" class="spinner spinner-border text-dark" role="status">
      <span class="visually-hidden">Loading...</span>
   </div>
   <div class="mt-2" style="width: 95%;">
      <h1 class="m-0">
         {{ model.title }}
         <div class="player float-end">
            <i
               class="bi bi-caret-{{ isCollapsed ? 'down' : 'up' }}-square"
               placement="end"
               (click)="isCollapsed = !isCollapsed"
               [attr.aria-expanded]="!isCollapsed"
               aria-controls="collapseExample"
            ></i>
         </div>
      </h1>
      <p class="description" [ngClass]="{ collapsed: isCollapsed }">
         {{ model.seo.metaDescription }}
      </p>
   </div>
   <div class="btn-group d-none d-lg-block" role="group">
      <button
         *ngFor="let v of VIEWS"
         [ngClass]="{ active: current_view === v }"
         (click)="changeView(v)"
         type="button"
         class="btn btn-secondary"
      >
         <ng-container *ngIf="v == 1; else view_gt_one">
            {{ (v == 1 ? 'MAP.SINGLE_VIEW' : 'MAP.VIEW') | translate }}
         </ng-container>
         <ng-template #view_gt_one> {{ 'MAP.VIEW' | translate }} {{ v }} </ng-template>
      </button>
   </div>
   <div class="mt-3" *ngIf="(selected_features['1'] || []).length">
      <h4 translate>MAP.SELECTION</h4>
      <div style="overflow-x: auto">
         <table class="table infobox-table">
            <thead>
               <tr>
                  <th style="width: 33%" scope="col">{{ 'MAP.MAP' | translate }}</th>
                  <th style="width: 33%" scope="col">{{ 'MAP.UNIT' | translate }}</th>
                  <th style="width: 33%" scope="col">{{ 'MAP.VALUE' | translate }}</th>
               </tr>
            </thead>
            <tbody *ngFor="let v of Array(current_view).fill(1); let i = index">
               <ng-container *ngFor="let f of selected_features[(i + 1).toString()]">
                  <tr *ngFor="let p of Object.entries(f.properties)">
                     <td scope="col">{{ i + 1 }}</td>
                     <td>{{ findUnit(i + 1, selected_theme[(i + 1).toString()].name) }}</td>
                     <td>{{ formatValue(p[1]) }}</td>
                  </tr>
               </ng-container>
            </tbody>
         </table>
      </div>
   </div>

   <div class="view-config-container">
      <div
         *ngFor="let v of Array(current_view).fill(1); let i = index"
         class="view-config {{ loading_layers ? 'view-config-loading' : '' }}"
      >
         <h3 *ngIf="i == 1" translate>SELECT_TO_COMPARE</h3>
         <h2 *ngIf="i != 0">{{ 'MAP.MAP' | translate }} {{ i + 1 }}</h2>
         <h4 translate>MAP.SUBTHEMES</h4>
         <div ngbDropdown class="d-inline-block" style="width: 95%">
            <button type="button" class="btn btn-outline-primary" id="dropdownBasic1" ngbDropdownToggle>
               {{ selected_theme[(i + 1).toString()] ? printThemeTitle(selected_theme[(i + 1).toString()]) : ('SELECT_THEME' | translate) }}
            </button>
            <div ngbDropdownMenu aria-labelledby="dropdownBasic1">
               <button *ngFor="let t of i == 0 ? model.theme : all_themes" ngbDropdownItem (click)="changeMapTheme(i + 1, t)">
                  {{ printThemeTitle(t) }}
               </button>
            </div>
         </div>

         <!-- <pre *ngIf="selected_theme_layers[(i + 1).toString()]">{{ selected_theme_layers[(i + 1).toString()] | json }}</pre> -->

         <div *ngIf="selected_theme[(i + 1).toString()]?.filters">
            <h4 translate>MAP.FILTERS</h4>
            <!-- <pre>{{selected_filter[(i+1).toString()]|json}}</pre> -->
            <div *ngFor="let f of selected_theme[(i + 1).toString()]?.filters">
               <div ngbDropdown class="d-inline-block" style="width: 95%">
                  <label>{{ 'SELECT_FILTER.' + f.name | translate }}<i
                        *ngIf="hasTranslation('HELPER_FILTER.' + f.name)"
                        class="bi bi-info-circle ps-2"
                        style="cursor: pointer"
                        placement="end"
                        [ngbPopover]="popContent"
                        [popoverTitle]="'SELECT_FILTER.' + f.name | translate"
                        triggers="click:blur"
                        container="body"
                     ></i></label>
                  <ng-template #popContent>
                     <div class="popover-content" [innerHTML]="sanitizeHTML(f.name)"></div>
                  </ng-template>

                  <button type="button" class="btn btn-outline-primary" id="{{ f.name }}" ngbDropdownToggle [disabled]="loading_layers">
                     {{
                        (selected_filter[(i + 1).toString()] && selected_filter[(i + 1).toString()][f.name]
                           ? 'FILTER.' + f.name + '.' + selected_filter[(i + 1).toString()][f.name].name
                           : 'SELECT'
                        ) | translate
                     }}
                  </button>
                  <div ngbDropdownMenu aria-labelledby="dropdownBasic1">
                     <button
                        *ngFor="let fv of filters[f.name]"
                        class="filter-option"
                        [ngClass]="{
                           unavailable:
                              !isLayerAvailable[i + 1] || !isLayerAvailable[i + 1][f.name] || !isLayerAvailable[i + 1][f.name][fv.name],
                           notvalid:
                              !isFilterOptionValid[i + 1] ||
                              !isFilterOptionValid[i + 1][f.name] ||
                              !isFilterOptionValid[i + 1][f.name][fv.name]
                        }"
                        [disabled]="
                           !isFilterOptionValid[i + 1] ||
                           !isFilterOptionValid[i + 1][f.name] ||
                           !isFilterOptionValid[i + 1][f.name][fv.name]
                        "
                        ngbDropdownItem
                        (click)="changeFilter(i + 1, f, fv)"
                        translate
                     >
                        FILTER.{{ f.name }}.{{ fv.name }}
                     </button>
                  </div>
                  <div *ngIf="i == 0 && f.name == MONTHS && getMonthsNumber(1) >= 12" class="player float-end">
                     <i class="bi bi-play-btn{{ slider_playing ? '-fill' : '' }} ps-2" placement="end" (click)="slider_play()"></i>
                     <i class="bi bi-pause-btn{{ !slider_playing ? '-fill' : '' }} ps-2" placement="end" (click)="slider_pause()"></i>
                  </div>
               </div>
               <div
                  *ngIf="
                     i == 0 &&
                     f.name == MONTHS &&
                     getMonthsNumber(1) >= 12 &&
                     selected_filter[(i + 1).toString()] &&
                     selected_filter[(i + 1).toString()][f.name]
                  "
                  class="custom-slider"
                  style="width: 95%;"
               >
                  <ngx-slider
                     *ngIf="isPlatformBrowser"
                     [(value)]="slider_value"
                     [options]="slider_options"
                     (userChange)="onSliderChange($event)"
                  ></ngx-slider>
               </div>
            </div>
         </div>
         <div *ngIf="legends[(i + 1).toString()]" class="infobox-map-div">
            <div class="d-flex justify-content-between align-items-center" style="width: 95%;">
               <h4 translate>MAP.LEGEND</h4>
               <div class="player float-end">
                  <i
                  class="bi bi-caret-{{collapseLegend[(i + 1).toString()]?'up':'down'}}-square"
                     placement="end"
                     (click)="collapseLegend[(i + 1).toString()] = !collapseLegend[(i + 1).toString()]"
                     type="button"
                     data-bs-toggle="collapse"
                     [attr.data-bs-target]="'#legendCollapse' + (i + 1)"
                     [attr.data-target]="'#legendCollapse' + (i + 1)"
                     aria-expanded="false"
                     [attr.aria-controls]="'legendCollapse' + (i + 1)"
                  ></i>
               </div>
            </div>

            <div style="height: 100%" class="collapse" id="legendCollapse{{i+1}}">
               <img [src]="legends[(i + 1).toString()]" />
            </div>
         </div>
      </div>
   </div>
</div>
