import { isPlatformBrowser, Location } from '@angular/common';
import { Component, HostBinding, Inject, OnInit, PLATFORM_ID } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { AbstractComponent, SnackbarService } from '@sciamlab/ng-common';
import { StrapiService } from '@sciamlab/ng-common-strapi';
import { environment } from 'src/environments/environment.prod';

@Component({
   selector: 'app-root',
   templateUrl: './app.component.html',
   styleUrls: ['./app.component.scss'],
})
export class AppComponent extends AbstractComponent implements OnInit {
   settings: any;
   @HostBinding('class') public cssClass = '';
   analytics_key: any;

   constructor(
      route: ActivatedRoute,
      router: Router,
      snackbarService: SnackbarService,
      translate: TranslateService,
      @Inject(PLATFORM_ID) private platformId: any,
      private strapi: StrapiService,
   ) {
      super(route, router, snackbarService, translate);
   }

   async ngOnInit(): Promise<any> {
      this.settings = this.strapi.getSettings();
      if (this.settings.analytics_key) {
         this.analytics_key = this.settings.analytics_key;
      }

      if (isPlatformBrowser(this.platformId)) {
         const link: any = document.createElement('link')
         const head: any = document.querySelector('head')
         link.href = environment.cms
         link.rel = "preconnect"
         head.appendChild(link)

         const favicon: any = document.querySelector('#favicon');
         favicon.href = this.strapi.getImageUrl(this.settings.favicon, 'thumbnail');
      }
   }
}
