<button 
   [ngClass]="{'active': active }" 
   (click)="toggleGeolocate()"
   type="button" 
   placement="left" 
   ngbTooltip="{{'MAP.GEOLOCATION' | translate}}"
>
   <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-record-circle"
      viewBox="0 0 16 16">
      <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
      <path d="M11 8a3 3 0 1 1-6 0 3 3 0 0 1 6 0z" />
   </svg>
</button>