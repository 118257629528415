import { Component, Injectable, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Resolve, Router } from '@angular/router';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { StrapiService } from '@sciamlab/ng-common-strapi';
import { environment } from 'src/environments/environment';
import { AbstractComponent, SnackbarService, MetaService } from '@sciamlab/ng-common';

@Component({
   selector: 'app-footer',
   templateUrl: './footer.component.html',
   styleUrls: ['./footer.component.scss'],
})
export class FooterComponent extends AbstractComponent implements OnInit, OnDestroy {

   environment = environment;
   model: any;
   settings: any;
   langChangeSubscription!: Subscription;

   constructor(
      route: ActivatedRoute,
      router: Router,
      snackbarService: SnackbarService,
      translate: TranslateService,
      public metaService: MetaService,
      protected strapi: StrapiService,
   ) {
      super(route, router, snackbarService, translate);
   }
   
   async ngOnInit(): Promise<void> {
      this.langChangeSubscription = this.translate.onLangChange.subscribe(async (event: LangChangeEvent) => {
         this.model = await this.strapi.getSingleType('footer', this.translate.currentLang, 'info,links,links.routing');
      });
      
      this.model = await this.strapi.getSingleType('footer', this.translate.currentLang, 'info,links,links.routing')
      this.settings = this.strapi.getSettings();
   }

   ngOnDestroy() {
      if (this.langChangeSubscription) this.langChangeSubscription.unsubscribe();
   }
}