import { Component, Inject, OnInit, PLATFORM_ID, ViewChild } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { MetaService, SnackbarService } from '@sciamlab/ng-common';
import { SingleTypeComponent, StrapiService } from '@sciamlab/ng-common-strapi';
import { AppComponent } from 'src/app/app.component';

import { SwiperOptions } from 'swiper';

@Component({
   selector: 'app-home',
   templateUrl: './home.component.html',
   styleUrls: ['./home.component.scss'],
})
export class HomeComponent extends SingleTypeComponent implements OnInit {
   maps: any = [];
   page_content_rendered!: SafeHtml;

   constructor(
      route: ActivatedRoute,
      router: Router,
      strapi: StrapiService,
      translate: TranslateService,
      meta: MetaService,
      snackbarService: SnackbarService,
      private appComponent: AppComponent,
      private sanitizer: DomSanitizer,
   ) {
      super(route, router, snackbarService, translate, meta, strapi);
   }

   async ngOnInit(): Promise<void> {
      await super.ngOnInit();

      this.maps = (await this.strapi.filterCollectionType(
         'maps', 
         undefined,
         undefined,
         undefined,
         undefined,
         this.translate.currentLang, 
         'image,cover,seo.metaImage'
      )).data;

      this.page_content_rendered = this.sanitizer.bypassSecurityTrustHtml(this.model.intro)
   }

   @ViewChild('swiper', { static: false }) swiper?: any;

   config: SwiperOptions = {
      slidesPerView: 1,
      navigation: true,
      pagination: true,
      spaceBetween: 20,
      scrollbar: { draggable: true },
      // breakpoints: {
      //    '575': {
      //       slidesPerView: 2,
      //       spaceBetween: 23,
      //     },
      //    '768': {
      //       slidesPerView: 3,
      //       spaceBetween: 23,
      //     },
      //    '991': {
      //       slidesPerView: 4,
      //       spaceBetween: 23,
      //     }
      // },
   };

   slideNext() {
      this.swiper.swiperRef.slideNext(100);
   }

   slidePrev() {
      this.swiper.swiperRef.slidePrev(100);
   }

   getImageUrl(url: string): string {
      return url ? this.strapi.getImageUrl(url) : 'https://via.placeholder.com/562x295?text=Immagine+non+disponibile';
   }
}
