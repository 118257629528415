import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { AbstractComponent, SnackbarService, MetaService } from '@sciamlab/ng-common';
import { StrapiService } from '@sciamlab/ng-common-strapi';

@Component({
   selector: 'app-error',
   templateUrl: './error.component.html',
   styleUrls: ['./error.component.scss'],
})
export class ErrorComponent extends AbstractComponent implements OnInit {

   error_code: number;

   constructor(
      route: ActivatedRoute,
      router: Router,
      snackbarService: SnackbarService,
      translate: TranslateService,
      public metaService: MetaService,
      protected strapi: StrapiService,
   ) {
      super(route, router, snackbarService, translate);
      this.error_code = this.route.snapshot.params.code || 404;
   }

   ngOnInit(): void {
      // noop
   }
}
