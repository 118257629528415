import { Component, Input, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';
import * as luxon from 'luxon';
import { StrapiService } from '@sciamlab/ng-common-strapi';

@Component({
   selector: 'app-news-card',
   templateUrl: './news-card.component.html',
   styleUrls: ['./news-card.component.scss'],
})
export class NewsCardComponent implements OnInit {
   @Input() news!: any;
   environment = environment;
   luxon = luxon;
   constructor(private strapi: StrapiService) {
      // do nothing
   }

   ngOnInit(): void {
      // do nothing
   }

   getCoverUrl(): any {
      return this.news.meta.image
         ? this.strapi.getImageUrl(this.news.meta.image.url)
         : 'https://via.placeholder.com/562x295?text=Immagine+non+disponibile';
   }
}
